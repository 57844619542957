import i18n from "locales/i18n";
import * as cts from "../../../constants/ui/svg";
import noAva from "../../../assets/images/noAva.png";

import { createSearchParams, Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchChannels } from "slices/channelSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import moment from "moment";
import { URL_UPLOAD_IMAGE } from "../../../constants";

const MediaView = (props: any) => {
  // const { channelsSt } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const channelsSt = useAppSelector((state) => state.channels.channels);

  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  useEffect(() => {
    dispatch(fetchChannels({ channelName: "general" }));
  }, []);
  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  }, []);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <>
      <div className="flex w-full pb-[50px] delivery_home justify-center max-500px:pb-[120px]">
        <div className="flex flex-col size_content_hd w-full">
          <div className="flex justify-between pb-[20px] max-500px:pb-[12px]">
            <div className="relative flex items-center flex-1">
              <div className="h-[36px] w-[36px] max-500px:h-[28px] max-500px:w-[28px] rounded-[50%] bg-[#F7B27A]"></div>
              <span className="text-[#101828] max-500px:text-[16px] max-500px:left-[7px] text-[20px] notosans_bold absolute left-[16px]">
                {i18n.t("DURING_DELIVERY")}
              </span>
            </div>
            <Link
              onClick={() => window.scrollTo(0, 0)}
              to="/delivery"
              className="flex items-center cursor-pointer"
            >
              <span className="text-[14px] max-500px:text-[13px] max-500px:text-[#667085] text-[#101828] notosans_medium mr-[5px] w-full">
                {i18n.t("SHOW_MORE")}
              </span>
              <span>{cts.right1Icon}</span>
            </Link>
          </div>
          <div className="items-center grid max-500px:grid-cols-2 max-1080px:grid-cols-3 max-500px:gap-2 gap-4 grid-cols-4">
            {channelsSt
              ?.filter((i: any) => i?.user?.enableLive !== false)
              ?.filter((it: any, idx: number) => (width >= 1200 ? idx <= 9 : idx <= 7))
              ?.map((x: any, y: number) => {
                return (
                  <div
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate({
                        pathname: "/livestream",
                        search: createSearchParams({
                          key: `${x?.user?._id}`,
                        }).toString(),
                      });
                    }}
                    key={y}
                    className="relative max-500px:w-[45vw] max-500px:h-[45vw] w-full cursor-pointer hover:shadow-[0px_4px_8px_-2px_rgba(0,0,0,0.4)] rounded-[8px]"
                  >
                    <div className="flex flex-col absolute h-full w-full justify-between">
                      <div className="flex mt-[8px] mx-[8px] items-center justify-between w-11/12">
                        <span>{cts.live1Icon}</span>
                        <div></div>
                        {/* <div className="bg-[#FCFCFDB2] rounded-[4px] flex items-center justify-center h-[20px] w-[53px]">
                          <span className="sz12_view">{cts.view1Icon}</span>
                          <span className="text-[12px] notosans_normal ml-[5px]">
                            {countViews(x?.user?._id, roomsInfo)}
                          </span>
                        </div> */}
                      </div>
                      <div className="flex flex-col pt-[10px] bg_linear pb-[8px] px-[8px]">
                        <div className="bg-[#101828]/60 max-500px:hidden rounded-[2px] h-[22px] w-[78px] flex items-center justify-center text-white text-[12px] notosans_normal">
                          {moment(x?.updatedAt).format("MM/DD")}{" "}
                          <span className="ml-[5px]">{moment(x?.updatedAt).format("HH:mm")}</span>
                        </div>
                        {/* <span className="text-white text-[14px] notosans_bold my-[5px] text_1_line">
                          この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。
                        </span> */}
                        <div className="flex items-center text-white text-[12px] notosans_normal">
                          <img
                            className="h-[20px] w-[20px] rounded-[50%] mr-[5px] object-cover"
                            src={
                              x?.user?.avatar
                                ? `${URL_UPLOAD_IMAGE}/avatars/${x?.user?.avatar}`
                                : noAva
                            }
                          />
                          {x?.user?.nickName}
                        </div>
                      </div>
                    </div>

                    <img
                      className="w-full max-500px:w-[45vw] max-500px:h-[45vw] rounded-[8px] max-1080px:h-[25vw] max-800px:h-[25vw] h-[282px] object-cover"
                      src={
                        x?.user?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${x?.user?.avatar}` : noAva
                      }
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
export default MediaView;
