import React, { useEffect, useState } from "react";
import i18n from "locales/i18n";
import * as cts from "../../constants/ui/svg";
import { Button, Radio } from "antd";
import type { RadioChangeEvent } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import noAva from "../../assets/images/noAva.png";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import BreadcrumbComponent from "components/Common/Breadcrumb";
import Cookies from "js-cookie";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { formatMoney, formatNumber } from "helpers";
import { paymentActions } from "slices/paymentSlice";
import { Input } from "antd";
import { URL_UPLOAD_IMAGE } from "../../constants";
import { getUserInfo } from "slices/userSlice";
import { getToken } from "helpers/Token";
import { getDetailHost } from "slices/followSlice";
type Props = {};
const { TextArea } = Input;
const PurchasePoint = (props: Props) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchparams] = useSearchParams();

  const { beforePath, prevPath } = location.state || {};

  const [value, setValue] = useState(1);
  const [valueCoin, setValueCoin] = useState({ coin: 100, point: 100, type: "" });
  const [visibleInfo, setVisibleInfo] = useState(false);
  const [pointCustom, setPointCustom] = useState<any>(0);
  const navigate = useNavigate();

  const idHost = searchparams.get("key")?.toString() || "";
  const detailHost = useAppSelector((state) => state.follow.detailHost);
  const userInfo = useAppSelector((state) => state.users.userInfo);
  const token = getToken();
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const arrCoin = [
    { coin: 100, point: 100 },
    { coin: 500, point: 500 },
    { coin: 1000, point: 1000 },
    { coin: 10000, point: 10000 },
    { coin: pointCustom, point: pointCustom, type: "custom" },
  ];
  const arrPayment = [
    {
      method: i18n.t("CREDIT_CARD"),
      payment: (
        <div className="flex items-center">
          <span className="mr-[4px]">{cts.visaPayment}</span>
          <span className="mr-[4px]">{cts.mastercardPayment}</span>
          <span className="mr-[4px]">{cts.JCBPayment}</span>
          <span className="mr-[4px]">{cts.amexPayment}</span>
          <span className="">{cts.DinersPayment}</span>
        </div>
      ),
    },
    // {
    //   method: "Paypal",
    //   payment: (
    //     <Button className="border border-[#F2F4F7] flex items-center justify-center bg-white rounded-[6px] h-[40px] w-[58px]">
    //       {cts.paypalPayment}
    //     </Button>
    //   ),
    // },
    // { method: i18n.t("INTERNET_BANK") },
    // {
    //   method: i18n.t("ELECTRONIC_MONEY"),
    //   payment: (
    //     <div className="flex items-center">
    //       <Button className="border border-[#F2F4F7] flex items-center justify-center bg-white rounded-[6px] h-[40px]  mr-[8px]">
    //         {cts.applePay}
    //       </Button>
    //       <Button className="border border-[#F2F4F7] flex items-center justify-center bg-white rounded-[6px] h-[40px] mr-[8px]">
    //         {cts.googlePay}
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  const arrPaymentMb = [
    {
      method: i18n.t("CREDIT_CARD"),
      payment: (
        <div className="flex items-center">
          <button className="mr-[4px]">{cts.visaPaymentMb}</button>
          <button className="mr-[4px]">{cts.mastercardPaymentMb}</button>
          <button className="mr-[4px]">{cts.JCBPaymentMb}</button>
          <button className="mr-[4px]">{cts.amexPayment}</button>
          <button className="mr-[4px]">{cts.DinersPayment}</button>
        </div>
      ),
    },
  ];
  useEffect(() => {
    if (idHost) {
      dispatch(getDetailHost(idHost as string));
    }
  }, [idHost]);
  useEffect(() => {
    dispatch(getUserInfo({ token: token }));
  }, []);
  return (
    <div className="justify-center flex w-full bg-white border-[#D0D5DD] border-t max-500px:justify-normal max-500px:relative">
      {visibleInfo && (
        <div className="hidden max-500px:block absolute max-500px:bg-black max-500px:bg-opacity-75 max-500px:transition-opacity top-0 right-0 bottom-0 left-0"></div>
      )}
      <div className="size_content_hd w-full pt-[10px] pb-[40px] max-500px:pt-3 ">
        <div className="mb-[30px]  max-500px:hidden max-500px:px-4">
          {prevPath !== undefined && beforePath !== undefined ? (
            <BreadcrumbComponent
              breadMain={i18n.t("PURCHASE_POINT")}
              breadChild={detailHost?.nickName}
              breadBefore={prevPath}
            />
          ) : prevPath !== undefined && beforePath === undefined ? (
            <BreadcrumbComponent
              breadChild={i18n.t("PURCHASE_POINT")}
              breadMain={detailHost?.nickName}
            />
          ) : (
            <BreadcrumbComponent breadMain={i18n.t("PURCHASE_POINT")} />
          )}
          {/* <BreadcrumbComponent 
              breadBefore={prevPath}
              breadMain={i18n.t("PURCHASE_POINT")} /> */}
        </div>
        <span className="text-[24px]  text-[#101828] notosans_bold max-500px:text-[18px] max-500px:px-4">
          {i18n.t("PURCHASE_POINT")}
        </span>
        <div className="flex items-center mt-[12px] max-500px:px-4">
          <span className="text-[16px]">{i18n.t("CURRENT_POINT")}</span>
          <span className="text-[16px] mx-[8px] notosans_bold">
            {formatMoney(Number(userInfo?.points))}
          </span>
          <span>{cts.coinIcon1}</span>
        </div>
        <div className="flex mt-[40px] max-500px:mt-[22px] max-500px:px-4">
          <div className="w-[70%] flex flex-col pr-[20px] border-r border-[#D0D5DD] max-500px:w-full max-500px:border-none max-500px:p-0 max-500px:w-f">
            <span className="text-[20px] notosans_bold max-500px:text-base">
              {i18n.t("SELECT_POINT")}
            </span>
            <div className="grid gap-3 grid-cols-5 mt-[20px] max-1080px:grid-cols-3 max-500px:mt-[10px] max-500px:gap-3 max-800px:!grid-cols-2">
              {arrCoin?.map((x, y) => {
                return x?.type === "custom" ? (
                  <div
                    onClick={() => {
                      setValueCoin({ coin: x.coin, point: x.point, type: x.type });
                      localStorage.setItem("total_payment", valueCoin.coin.toString());
                    }}
                    className={`${
                      valueCoin.coin === x.coin && valueCoin.point === x.point
                        ? "border-[#EF6820] bg-[#FEFAF5] bg_choose_input"
                        : "border-[#D0D5DD]"
                    } border cursor-pointer relative bg_choose_input_hover items-center hover:border-[#EF6820] hover:bg-[#FEFAF5] rounded-[10px] flex flex-col flex-1 justify-end`}
                    key={y}
                  >
                    <div className="flex justify-end h-full cursor-pointer relative text_input_coin">
                      {/* <input
                          // type="number"
                          value={pointCustom > 0 ? formatMoney(pointCustom) : "カスタム"}
                          onChange={(e) => {
                            const value = (e.target.value || "0").replace(/[^0-9]/g, "");
                            // if (isNaN(Number(e.target.value))) return;
                            setPointCustom(Number(parseInt(value, 10)));
                            setValueCoin({
                              coin: parseInt(value, 10),
                              point: parseInt(value, 10),
                              type: x.type,
                            });
                          }}
                          className="flex items-center text-[20px] notosans_bold bg-transparent outline-none text-center  w-[100px]"
                        /> */}
                      {valueCoin.coin === x.coin && valueCoin.point === x.point && (
                        <TextArea
                          maxLength={7}
                          value={pointCustom === 0 ? " " : formatMoney(pointCustom)}
                          onChange={(e) => {
                            const value = (e.target.value || "0").replace(/[^0-9]/g, "");
                            console.log("value text", typeof value);
                            // if (isNaN(Number(e.target.value))) return;
                            if (
                              typeof e.target.value === "string" ||
                              Number(isNaN(Number(pointCustom)))
                            ) {
                              setPointCustom(0);
                            }
                            setPointCustom(Number(parseInt(value, 10)));
                            setValueCoin({
                              coin: parseInt(value, 10),
                              point: parseInt(value, 10),
                              type: x.type,
                            });
                          }}
                          placeholder="Controlled autosize"
                          autoSize={{ minRows: 1, maxRows: 1 }}
                          style={{
                            height: "100% !important",
                            width: "100%",
                            cursor: "pointer !important",
                            textAlign: "center",
                          }}
                        />
                      )}

                      <div
                        className={`${
                          valueCoin.coin === x.coin && valueCoin.point === x.point && "hidden"
                        } flex items-center justify-center w-fit h-[30px] mt-[12px]`}
                      >
                        <span className="h-[30px] text-[20px] notosans_bold">カスタム</span>
                      </div>
                      <span
                        className={`${
                          valueCoin.coin === x.coin && valueCoin.point === x.point && "absolute"
                        }  mt-[12px] h-[30px] flex items-center flex-1 right-[10px]`}
                      >
                        {cts.coinIcon1}
                      </span>
                    </div>

                    <span
                      className={`${
                        pointCustom <= 0
                          ? "text-[#98A2B3] text-[14px] notosans_normal"
                          : "text-[#EF6820] text-[20px] notosans_bold"
                      } cursor-pointer absolute mb-[10px] h-[30px] items-center flex flex-col`}
                    >
                      {/* {valueCoin.coin === x.coin && valueCoin.point === x.point && (
                        <span className="h-px w-full bg-[rgba(0,0,0,0.3)]"></span>
                      )} */}
                      {pointCustom <= 0
                        ? "大容量に対応"
                        : `${i18n.t("YEN")}` + formatMoney(x.point)}
                    </span>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setValueCoin({ coin: x.coin, point: x.point, type: "" });
                      localStorage.setItem("total_payment", x.coin.toString());
                      setPointCustom(0);
                    }}
                    className={`${
                      valueCoin.coin === x.coin && valueCoin.point === x.point
                        ? "border-[#EF6820] bg-[#FEFAF5]"
                        : "border-[#D0D5DD]"
                    } border cursor-pointer items-center hover:border-[#EF6820] hover:bg-[#FEFAF5] rounded-[10px] flex flex-col flex-1 py-[12px] justify-center`}
                    key={y}
                  >
                    <span className="flex items-center text-[20px] notosans_bold">
                      {formatNumber(x.coin)} {cts.coinIcon1}
                    </span>
                    <span className="text-[#EF6820] text-[20px] notosans_bold mt-[8px]">
                      {i18n.t("YEN")}
                      {formatMoney(x.point)}
                    </span>
                  </div>
                );
              })}
            </div>
            <span className="text-[20px] mt-[40px] notosans_bold max-500px:mt-5">
              {i18n.t("PAYMENT_OF_PAYMENT")}
            </span>
            <div className="flex flex-col text-base notosans_norma my-3 max-500px:my-2">
              <span>・ご注文後すぐ購入完了となります。</span>
              <span>・VISA、MasterCard、JCB、AMEX、DINERSカードなどをご利用いただけます。</span>
            </div>
            <Radio.Group className="payment_radio" onChange={onChange} value={value}>
              <div className=" grid grid-cols-1 gap-4 max-500px:hidden ">
                {arrPayment.map((x, y) => {
                  return (
                    <div
                      key={y}
                      className={`${
                        y === 2 ? "py-[28px]" : "py-[20px]"
                      } border px-[24px] max-890px:flex-col max-890px:py-[10px] max-890px:items-start max-1080px:px-[10px] border-[#D0D5DD] rounded-[8px] flex justify-between items-center `}
                    >
                      <Radio value={x.method}>{x.method}</Radio>
                      <div>{x.payment}</div>
                    </div>
                  );
                })}
              </div>
              <div className="hidden  grid-cols-1 max-500px:grid  mt-[10px] gap-2">
                {arrPaymentMb.map((x, y) => {
                  return (
                    <div
                      key={y}
                      className={`border  border-[#D0D5DD] rounded-[8px] flex justify-between items-center max-500px:text-sm p-[20px]`}
                    >
                      <Radio value={x.method}>{x.method}</Radio>
                      <div>{x.payment}</div>
                    </div>
                  );
                })}
              </div>
            </Radio.Group>
          </div>
          <div className="w-[400px] pl-[20px] max-500px:hidden">
            <span className="text-[20px] notosans_bold">{i18n.t("ORDER_INFORMATION")}</span>
            <div className="mt-[20px] bg-[#F9FAFB] rounded-[10px] px-[20px] py-[16px]">
              <span className="text-[#475467] text-[16px] notosans_bold">
                {i18n.t("ACCOUNT_INFORMATION")}
              </span>
              <div className="flex items-center mb-[16px] py-[17px] border-b border-[#D0D5DD]">
                <img
                  className="rounded-[25px] h-[50px] w-[50px]"
                  alt="avatar"
                  src={userInfo?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${userInfo?.avatar}` : noAva}
                />
                <div className="ml-[12px] flex flex-col">
                  <span className="text-[16px] notosans_bold">{userInfo?.nickName}</span>
                  <span className="text-[#475467] text-[16px] mt-[4px]">({userInfo?.email})</span>
                </div>
              </div>
              <span className="text-[#475467] text-[16px] notosans_bold">
                {i18n.t("PURCHASE_DETAIL")}
              </span>
              <div className="flex items-center justify-between py-[16px] border-b border-[#D0D5DD]">
                <span className="text-[16px] text-[#101828] flex items-center">
                  {formatNumber(valueCoin.coin)} {cts.coinIcon1}{" "}
                </span>
                <span className="text-[#101828] text-[16px]">{formatMoney(valueCoin.point)}</span>
              </div>
              <div className="flex items-center justify-between mt-[16px]">
                <span className="text-[18px] max-1350px:text-[16px] text_1_line text-[#101828] notosans_bold">
                  {i18n.t("TOTAL")}
                </span>
                <span className="text-[#101828] text-[18px] text_1_line max-1350px:text-[16px] notosans_bold">
                  {formatMoney(valueCoin.point)}
                </span>
              </div>
            </div>
            <Button
              disabled={!userInfo?._id}
              onClick={() => {
                // Cookies.set("totalMoney", valueCoin.point.toString());
                if (valueCoin.point !== 0) {
                  dispatch(paymentActions.setTotalPayment(Number(valueCoin.point)));
                  localStorage.setItem("total_payment", valueCoin.point.toString());

                  window.scrollTo(0, 0);
                  navigate({
                    pathname: "/payment-square",
                  });
                } else {
                  alert("please choose point");
                }
              }}
              className="bg-[#EF6820] hover:!text-white notosans_bold h-[48px] rounded-[8px] w-full flex items-center justify-center text-white shadow-[0px_1px_2px_0px_#1018280D] mt-[20px]"
            >
              {i18n.t("PAY")}
            </Button>
          </div>
        </div>

        {visibleInfo ? (
          <div className="hidden max-500px:flex rounded-t-lg border-t px-4 pt-3 flex-col pb-3 absolute bg-white z-10 left-0 right-0 bottom-0  transition-all duration-300 -translate-y-0">
            <div className="flex flex-1">
              <span className="text-[#475467] text-[16px] notosans_bold flex-1">
                {i18n.t("ORDER_INFORMATION")}
              </span>
              <button onClick={() => setVisibleInfo(false)}>
                <IoCloseSharp size={16} />
              </button>
            </div>
            <div className="mt-[12px]  ">
              <span className="text-[#475467] text-[16px] notosans_bold">
                {i18n.t("ACCOUNT_INFORMATION")}
              </span>
              <div className="flex items-center pt-2 mb-3  pb-[12px] border-b border-[#D0D5DD]">
                <img
                  className="rounded-[25px] h-[50px] w-[50px]"
                  alt="avatar"
                  src={userInfo?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${userInfo?.avatar}` : noAva}
                />
                <div className="ml-[12px] flex flex-col">
                  <span className="text-[16px] notosans_bold">{userInfo?.nickName}</span>
                  <span className="text-[#475467] text-[16px] mt-[4px]">({userInfo?.email})</span>
                </div>
              </div>
              <span className="text-[#475467] text-[16px] notosans_bold">
                {i18n.t("PURCHASE_DETAIL")}
              </span>
              <div className="flex items-center justify-between py-[16px] border-b border-[#D0D5DD]">
                <span className="text-[16px] text-[#101828] flex items-center">
                  {formatNumber(valueCoin.coin)} {cts.coinIcon1}{" "}
                </span>
                <span className="text-[#101828] text-[16px]">{formatMoney(valueCoin.point)}</span>
              </div>
              <div className="flex items-center justify-between mt-[16px]">
                <span className="notosans_norma text-[14px] items-center text-[#101828] ">
                  {i18n.t("TOTAL")} ({i18n.t("INCLUDING_VAT")}){" "}
                </span>
                <div className="flex">
                  <span className="text-[#101828] text-xl notosans_bold items-center flex">
                    {formatMoney(valueCoin.point)}
                  </span>
                  <button
                    className="justify-center items-center ml-2"
                    onClick={() => setVisibleInfo(!visibleInfo)}
                  >
                    <FaAngleDown color="#EF6820" size={16} />
                  </button>
                </div>
              </div>
              <button
                disabled={!userInfo?._id}
                onClick={() => {
                  // Cookies.set("totalMoney", valueCoin.point.toString());
                  if (valueCoin.point !== 0) {
                    dispatch(paymentActions.setTotalPayment(Number(valueCoin.point)));

                    window.scrollTo(0, 0);
                    navigate({
                      pathname: "/payment-square",
                    });
                  } else {
                    alert("please choose point");
                  }
                }}
                className="flex justify-center items-center w-f py-2 bg-primary01 rounded-lg w-full mt-3"
              >
                <span className="notosans_bold text-base text-white">{i18n.t("PAY")}</span>
              </button>
            </div>
          </div>
        ) : (
          <div className="hidden max-500px:flex border-t border-b border-[##EAECF0] px-4 py-2 mt-3 flex-col ">
            <div className="flex mb-3">
              <span className="flex flex-1 notosans_norma text-[14px] items-center">合計</span>
              <span className="text-xl notosans_bold items-center flex">
                {formatMoney(valueCoin.point)}
              </span>
              <button
                className="justify-center items-center ml-2"
                onClick={() => setVisibleInfo(!visibleInfo)}
              >
                <FaAngleUp color="#EF6820" size={16} />
              </button>
            </div>
            <button
              disabled={!userInfo?._id}
              onClick={() => {
                // Cookies.set("totalMoney", valueCoin.point.toString());
                if (valueCoin.point !== 0) {
                  dispatch(paymentActions.setTotalPayment(Number(valueCoin.point)));

                  window.scrollTo(0, 0);
                  navigate({
                    pathname: "/payment-square",
                  });
                } else {
                  alert("please choose point");
                }
              }}
              className="flex justify-center items-center w-f py-2 bg-primary01 rounded-lg"
            >
              <span className="notosans_bold text-base text-white">{i18n.t("PAY")}</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PurchasePoint;
