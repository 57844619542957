import React, { useCallback, useEffect, useRef, useState } from "react";
import * as cts from "../../constants/ui/svg";
import { createSearchParams, Link, useNavigate, useSearchParams } from "react-router-dom";
import i18n from "locales/i18n";
import ava8 from "../../assets/images/HOST IMAGE/pic01.jpg";
import imgSchedule from "../../assets/images/Event-01.png";
import ava from "../../assets/images/HOST IMAGE/pic03 (9).jpg";
import ava2 from "../../assets/images/HOST IMAGE/pic01 (4).jpg";
import ava1 from "../../assets/images/HOST IMAGE/pic01 (3).jpg";
import noAva from "../../assets/images/noAva.png";
import ava3 from "../../assets/images/HOST IMAGE/pic01 (5).jpg";
import ava4 from "../../assets/images/HOST IMAGE/pic01 (6).jpg";
import ava5 from "../../assets/images/HOST IMAGE/pic01 (7).jpg";
import ava6 from "../../assets/images/HOST IMAGE/pic01 (8).jpg";
import ava7 from "../../assets/images/HOST IMAGE/pic01 (9).jpg";
import gift from "../../assets/images/redrose-01.png";
import gift1 from "../../assets/images/Balloon-01.png";
import gift2 from "../../assets/images/Crown-01.png";
import gift3 from "../../assets/images/Firework-01.png";
import gift4 from "../../assets/images/WineBottle-011.png";
import gift5 from "../../assets/images/Wine Glass-01.png";
import gift6 from "../../assets/images/Hat-01.png";
import gift7 from "../../assets/images/cake-011.png";
import { Button, Dropdown, Input, Modal } from "antd";
import EmojiPicker from "emoji-picker-react";
import { io } from "socket.io-client";
import type { MenuProps } from "antd";
import Alert from "antd";
// Constants
import { useAppDispatch, useAppSelector } from "app/hooks";
import { API_URL_BASE, SOCKET_SERVER, URL_UPLOAD_IMAGE } from "../../constants";
import { getToken } from "helpers/Token";
import { JWPlayer } from "components/Common/JWPlayer";

// Actions
import { createChatSuccess, fetchChats } from "slices/chatSlice";
import { getDetailEvent } from "slices/eventLiveSlice";
import { changeFollow, getFollow, getPointLiveStream } from "slices/followSlice";
import { getDetailHost } from "slices/followSlice";
import { getUserInfo } from "slices/userSlice";
import { fetchGiftNameList } from "slices/giftNameSlice";
import { onViewLive, successToast, errorToast, formatMoney } from "helpers";
import ButtonFollowComponent from "components/Common/ButtonFollow";
import { setOnlineClients, leaveRomClient } from "slices/channelSlice";
import { getLiveStream } from "slices/liveStreamSlice";

type Props = {};

const LiveStreamPage = (props: Props) => {
  const dispatch = useAppDispatch();
  const [searchparams] = useSearchParams();
  const key = searchparams.get("key");
  const socket: any = io(`${SOCKET_SERVER}/realtime`);
  const token = getToken();
  const navigate = useNavigate();
  const localData: any = localStorage.getItem("links") || [];

  const userInfo = useAppSelector((state) => state.users.userInfo);
  const detailEvent = useAppSelector((state) => state.eventLive.detailEvent);
  const detailHost = useAppSelector((state) => state.follow.detailHost);
  const listFollow = useAppSelector((state) => state.follow.listFollowInStream);
  const giftsName = useAppSelector((state) => state.giftName.giftsName);
  const channelsSt = useAppSelector((state) => state.channels.channels);
  const roomsInfo = useAppSelector((state) => state.channels.roomsInfo);
  const chats = useAppSelector((state) => state.chats.chats);
  const liveStream = useAppSelector((state) => state.liveStream.liveStream);
  const listRankingGift = useAppSelector((state) => state.follow.listRankingGift);

  const [selectGift, setSelectGift] = useState({ id: "", coin: 0, image: "", push: false });
  const [changeNameModal, setChangeNameModal] = useState(false);
  const [openBox, setOpenBox] = useState(false);
  const [emojiPicker, setEmojiPicker] = useState(false);
  const [valueChat, setValueChat] = useState<any>({});
  const [joined, setJoined] = useState(false);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [arrGift, setArrGift] = useState<any>([]);
  const [editName, setEditName] = useState(false);
  const [clickHeart, setClickHeart] = useState(false);
  const [changeName, setChangeName] = useState<any>(userInfo?.nickName);
  const [countView, setCountView] = useState<number>(0);
  const [isShowChat, setIsShowChat] = useState(true);
  const [isShowInfo, setIsShowInfo] = useState(true);
  const [arrNew, setArrNew] = useState<any[]>([
    { id: 1, ava: ava1, name: "アサヒ", chat: "この文章はダミーです。" },
    { id: 2, ava: ava2, name: "Jeans", chat: "この文章はダミーです。行間等を確認するため。" },
    { id: 3, ava: ava3, name: "櫻井絢都 ", chat: "😍😍😍" },
    {
      id: 4,
      ava: ava4,
      name: "とっとこ翔尊  ",
      chat: "文字の大きさ、量、字間、行間等を確認するため。",
    },
    {
      id: 5,
      ava: ava5,
      coin: <span className="mr-[8px]">{cts.kcoint}</span>,
      name: "Jeans  ",
      chat: " 👍",
    },
    { id: 6, ava: ava6, name: "未来 ", chat: "😆😆😆" },
    { id: 7, ava: ava7, name: "Mao ", chat: " この文章はダミーです。" },
  ]);
  const url = window.location.href;
  const items: MenuProps["items"] = [
    {
      label: (
        <div
          onClick={() => {
            navigator.clipboard.writeText(url);
            successToast("コピーされました");
          }}
          className="flex items-center my-[3px]"
        >
          <span>{cts.connectIcon}</span>
          <span className="text-[14px] ml-[12px] notosans_medium cursor-pointer text-[#1D2939]">
            {i18n.t("COPY_LINK")}{" "}
          </span>
        </div>
      ),
      key: "1",
    },
    // {
    //   label: (
    //     <div className="flex items-center my-[3px]">
    //       <span>{cts.emailIcon}</span>
    //       <span className="text-[14px] ml-[12px] notosans_medium cursor-pointer text-[#1D2939]">
    //         {i18n.t("COPY_EMAIL")}{" "}
    //       </span>
    //     </div>
    //   ),
    //   key: "2",
    // },
    // {
    //   label: (
    //     <div className="flex items-center my-[3px]">
    //       <span>{cts.lineIcon1}</span>
    //       <span className="text-[14px] ml-[12px] notosans_medium cursor-pointer text-[#1D2939]">
    //         {i18n.t("SHARE")} : LINE
    //       </span>
    //     </div>
    //   ),
    //   key: "3",
    // },
    // {
    //   label: (
    //     <div className="flex items-center my-[3px]">
    //       <span>{cts.twitterIcon1}</span>
    //       <span className="text-[14px] ml-[12px] notosans_medium cursor-pointer text-[#1D2939]">
    //         {i18n.t("SHARE")} : X (Twitter)
    //       </span>
    //     </div>
    //   ),
    //   key: "4",
    // },
    // {
    //   label: (
    //     <div className="flex items-center my-[3px]">
    //       <span>{cts.instagramIcon}</span>
    //       <span className="text-[14px] ml-[12px] notosans_medium cursor-pointer text-[#1D2939]">
    //         {i18n.t("SHARE")} : Instagram
    //       </span>
    //     </div>
    //   ),
    //   key: "5",
    // },
  ];
  const itemsEdit: MenuProps["items"] = [
    {
      label: (
        <div
          onClick={() => {
            setChangeNameModal(true);
            setEditName(true);
          }}
          className="flex items-center my-[3px]"
        >
          <span>{cts.iconEditName}</span>
          <span className="text-[14px] ml-[12px] notosans_normal cursor-pointer text-[#1D2939]">
            {i18n.t("EDIT_NAME")}{" "}
          </span>
        </div>
      ),
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div onClick={() => setIsShowChat((prev) => !prev)} className="flex items-center my-[3px]">
          <span>{isShowChat ? cts.iconHidden : cts.showChat}</span>
          <span className="text-[14px] ml-[12px] notosans_normal cursor-pointer text-[#1D2939]">
            {isShowChat ? i18n.t("HIDDEN") : "表示"}{" "}
          </span>
        </div>
      ),
      key: "2",
    },
  ];
  useEffect(() => {
    if (key && roomsInfo?.length > 0) {
      const findRoom = roomsInfo.find((i: any) => i?.room === key);
      if (findRoom) {
        setCountView(findRoom?.countClients - 1 || 0);
      }
    }
  }, [roomsInfo, key]);
  const checkLeaveRoomOld = useCallback(async () => {
    const roomOld = localStorage.getItem("room_old");
    if (roomOld !== key) {
      await socket.emit("leaveRoom", roomOld);
    }

    socket.emit("joinRoom", key);

    socket.on("joinedRoom", (data: any) => {
      console.log("data", data);
      localStorage.setItem("room_old", data?.room);
      dispatch(setOnlineClients(data));
    });

    socket.on("leftRoom", (data: any) => {
      dispatch(leaveRomClient({ room: data?.room, countClients: data?.countClients }));
    });

    socket.on("chatToClient", (msg: any) => {
      dispatch(createChatSuccess(msg));
    });
  }, []);

  // This could be useState, useOptimistic, or other state
  // let pending = true;

  // useEffect(() => {
  //   function beforeUnload(e: any) {
  //     alert("Your file is being uploaded!");
  //     if (!pending) return;
  //     e.preventDefault();
  //   }

  //   window.addEventListener("beforeunload", beforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", beforeUnload);
  //   };
  // }, [pending]);
  useEffect(() => {
    if (clickHeart) {
      const data = {
        sender: userInfo,
        receiver: key,
        room: key,
        message: clickHeart,
        coins: selectGift?.coin || 0,
        gift: selectGift?.id || null,
        nickName: changeName || null,
      };
      if (joined) {
        socket.emit("chatToServer", data);
        // dispatch(createChatSuccess(data));
      }
    }
    // if (data) {
    const timeout = setTimeout(() => {
      setClickHeart(false);
    }, 800);
    return () => clearTimeout(timeout);
  }, [clickHeart]);
  const sendChatMessage = () => {
    if (openBox) {
      if (Number(userInfo?.points) < Number(selectGift?.coin)) {
        errorToast("さらにポイントを購入してください");
        // setTimeout(() => {
        //   navigate("/purchase-point");
        // }, 1000);
      } else {
        if ((selectGift?.coin || valueChat?.chat) && userInfo?._id) {
          const data = {
            sender: userInfo,
            receiver: key,
            room: key,
            message: valueChat.chat || selectGift.image,
            coins: selectGift?.coin || 0,
            gift: selectGift?.id || null,
            nickName: changeName || null,
          };

          if (joined) {
            socket.emit("chatToServer", data);
            // dispatch(createChatSuccess(data));
          }
          setTimeout(() => {
            dispatch(getUserInfo({ token: token }));
          }, 1000);
          setSelectGift({ id: "", coin: 0, image: "", push: true });
        }
      }
    } else {
      if ((selectGift?.coin || valueChat?.chat) && userInfo?._id) {
        const data = {
          sender: userInfo,
          receiver: key,
          room: key,
          message: valueChat.chat || selectGift.image,
          coins: selectGift?.coin || 0,
          gift: selectGift?.id || null,
          nickName: changeName || null,
        };

        if (joined) {
          socket.emit("chatToServer", data);
          // dispatch(createChatSuccess(data));
        }
        setTimeout(() => {
          dispatch(getUserInfo({ token: token }));
        }, 1000);
        setSelectGift({ id: "", coin: 0, image: "", push: true });
      }
    }
  };
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  const __renderButtonFollow = () => {
    return (
      <Button
        onClick={() => {
          if (userInfo?._id && key) {
            dispatch(changeFollow({ streamer: key, follower: userInfo?._id }));
          } else {
            errorToast("Please login!");
          }
        }}
        className={`${
          !listFollow?.find((i: any) => i?.follower?._id.includes(userInfo?._id))?.follower?._id
            ? "bg-[#EF6820] text-white hover:!border-[#EF6820] hover:!text-white"
            : "bg-[#EAECF0] text-[#475467] hover:!text-[#475467] border-none"
        } px-[12px] hover:text-none text-[14px] notosans_medium rounded-[8px] !flex !items-center max-500px:w-[120px] max-500px:justify-center `}
      >
        {!listFollow?.find((i: any) => i?.follower?._id.includes(userInfo?._id))?.follower?._id ? (
          <span className=" !flex !items-center">
            <span className="mr-[5px]">{cts.heartIcon}</span>

            {i18n.t("FOLLOW")}
          </span>
        ) : (
          <>{i18n.t("FOLLOWED")}</>
        )}
      </Button>
    );
  };
  const __renderGift = (item: any) => {
    return (
      <div className="max-800px:w-[50%] max-800px:flex max-800px:items-center">
        <div className="bg-[#F38744] max-800px:bg-[#1018284D] max-800px:p-[5px] max-800px:w-full  max-800px:rounded-[25px] max-800px:items-center max-800px:mx-[0px] mx-[16px] mb-[12px] rounded-[10px] p-[12px] flex justify-between items-start">
          <div className="flex items-center">
            <img
              className="h-[36px] w-[36px] max-800px:h-[40px] max-800px:w-[40px] rounded-[50%] mr-[8px] object-cover"
              src={
                item?.sender?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${item?.sender?.avatar}` : noAva
              }
            />
            <div className="flex flex-col">
              <span className="text-white text-[14px] max-800px:text-[13px] max-800px:!notosans_bold notosans_normal">
                {item?.sender?.nickName}
              </span>
              <span className="flex items-center max-800px:hidden text-white text-[14px] max-800px:text-[13px] notosans_normal">
                {item?.coins}
                {cts.coinIcon1}
              </span>
            </div>
          </div>
          <img
            className="h-[60px] max-800px:h-[45px] max-800px:w-[45px] w-[60px] rounded-[50%] object-cover"
            src={`${URL_UPLOAD_IMAGE}/gifts/${item?.message}`}
          />
        </div>
        <span className="flex items-center min-800px:hidden text-white text-[15px] ml-[5px] notosans_normal">
          {item?.coins}
          {cts.coinIcon1}
        </span>
      </div>
    );
  };
  const __renderContent = () => {
    return (
      <>
        <div className="absolute z-10 h-[90px] bg-gradient-to-b pt-[46px] px-[16px] from-[#202020]/70 via-[#202020]/40 to-[#202020]/0 w-[100vw]">
          <div className="flex items-center">
            <Link
              to="/"
              className={`${
                width > 800 && "hidden"
              } flex items-center border-none hover:!border-none shadow-none hover:!shadow-none mr-[12px]`}
            >
              <span>{cts.backHome}</span>
              <span className="text-[#475467] text-[14px] notosans_medium ml-[8px]">
                {i18n.t("HOME")}
              </span>
            </Link>
            <div
              className={`${
                isShowInfo === false && "hidden"
              } bg-[#1018284D] flex-1 mr-[12px] px-[6px] rounded-[100px] h-[44px] flex justify-between items-center`}
            >
              <div className="flex items-center">
                <img
                  src={
                    detailHost?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${detailHost?.avatar}` : noAva
                  }
                  className="h-[36px] object-cover w-[36px] rounded-[50%] mr-[4px]"
                />
                <div className="flex flex-col">
                  <span className="text-[14px] notosans_medium text-white">
                    {detailHost?.nickName}
                  </span>
                  <div className="flex items-center">
                    <span className="icon_respon_sub_color">{cts.subIcon}</span>
                    <span className="text-[13px] notosans_normal ml-[4px] text-white">
                      {listFollow?.length}
                    </span>
                  </div>
                </div>
              </div>
              {__renderButtonFollow()}
            </div>
            <Button
              className={`${
                isShowInfo === false && "hidden"
              } p-0 border-none shadow-none flex items-center justify-center rounded-[50%] bg-[#1018284D] h-[44px] w-[44px] hover:bg-[#1018284D]`}
            >
              <span className="icon_respon_share_color">{cts.shareLinkIcon}</span>
            </Button>
          </div>
        </div>
        <div
          className={`${
            openBox ? "" : "px-[16px] pb-[12px]"
          } h-[45%] flex flex-col justify-end bg-gradient-to-t from-[#202020]/80 via-[#202020]/40 to-[#202020]/0 w-[100vw] absolute bottom-[0px] z-10`}
        >
          <div
            onClick={() => setIsShowInfo((prev) => !prev)}
            className={`${
              (openBox && "hidden") || (emojiPicker && "hidden")
            } flex justify-end h-full relative flex-col box_chat overflow-y-auto pb-[8px] `}
          >
            <div className="overflow-auto box_chat_mobile">
              {chats
                ?.filter((it: any) => it?.status !== "blocked")
                ?.filter((it: any) => it?.message !== "true")
                ?.map((x: any, y: any) => {
                  return (
                    <>
                      {x?.coins > 0 ? (
                        __renderGift(x)
                      ) : (
                        <div key={y} className="flex pb-[12px]">
                          <img
                            className="h-[32px] w-[32px] rounded-[50%] mr-[8px] object-cover"
                            src={
                              x?.sender?.avatar
                                ? `${URL_UPLOAD_IMAGE}/avatars/${x?.sender?.avatar}`
                                : noAva
                            }
                          />
                          <div className="flex flex-col">
                            {x?.coin}
                            <span
                              // onClick={() => setFollow((prev) => !prev)}
                              className={`${
                                x.coin ? "text-[#EF6820]" : "text-white"
                              } text-[13px] notosans_normal`}
                            >
                              {x?.sender?.nickName}
                            </span>
                            <span className="text-[13px] notosans_normal text-white">
                              {x?.message}
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              {selectGift.coin !== 0 && selectGift.image !== "" && selectGift.push === true && (
                <div className="bg-[#101828]/30 rounded-[10px] px-[16px] py-[8px] flex justify-between items-center">
                  <div className="flex items-center">
                    <img
                      className="h-[32px] w-[32px] rounded-[50%] mr-[8px] object-cover"
                      src={
                        userInfo?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${userInfo?.avatar}` : noAva
                      }
                    />
                    <div className="flex flex-col">
                      <span className="text-white text-[13px] notosans_bold">
                        {userInfo?.nickName || userInfo?.userName}
                      </span>
                      <span className="flex items-center text-white text-[13px] notosans_normal">
                        {selectGift.coin}
                        <span className="ml-[5px]">{cts.coinIcon1}</span>
                      </span>
                    </div>
                  </div>
                  <img
                    className="h-[32px] w-[32px] rounded-[50%] object-cover"
                    src={selectGift.image}
                  />
                </div>
              )}
            </div>
          </div>
          {openBox && (
            <>
              <div className="flex items-center justify-between bg-white pt-[12px] px-[12px]">
                <div className="flex items-center">
                  <span className="text-[#101828] text-[14px] notosans_bold mr-[12px]">
                    {i18n.t("SUPPORT")}
                  </span>
                  <img
                    className="h-[20px] w-[20px] rounded-[50%] mr-[8px] object-cover"
                    src={
                      userInfo?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${userInfo?.avatar}` : noAva
                    }
                  />
                  <span className="text-[#101828] text-[14px] notosans_bold">
                    {changeName ? changeName : userInfo?.nickName}
                  </span>
                </div>
                <Button
                  onClick={() => {
                    setOpenBox(false);
                    setSelectGift({ id: "", coin: 0, image: "", push: true });
                  }}
                  className="border-none shadow-none p-0"
                >
                  {cts.close1Icon}
                </Button>
              </div>
              <div className="flex items-center bg-white pb-[12px] px-[12px]">
                <span className="text-[#101828] mr-[8px] text-[14px] notosans_normal">
                  {i18n.t("CURRENT_POINT")}
                </span>
                <span className="text-[#101828] text-[14px] notosans_bold mr-[2px]">
                  {formatMoney(Number(userInfo?.points)) || 0}
                </span>
                <span>{cts.coinIcon1}</span>
                <div
                  className="bg-[#EF6820] px-[16px] flex items-center h-[26px] rounded-[8px] cursor-pointer text-white ml-[16px] notosans_medium text-[12px]"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate(
                      {
                        pathname: "/purchase-point",
                      },
                      {
                        state: {
                          prevPath: detailHost?.nickName,
                        },
                      }
                    );
                    localStorage.setItem("buyCoin", "buyCoin");
                  }}
                >
                  <span className="text_1_line">{i18n.t("PURCHASE_POINT")}</span>
                </div>
                {/* <Link
                  onClick={() => localStorage.setItem("buyCoin", "buyCoin")}
                  className="bg-[#EF6820] px-[16px] flex items-center h-[26px] rounded-[8px] cursor-pointer text-white ml-[16px] notosans_medium text-[12px]"
                  to="/purchase-point"
                >
                  <span className="text_1_line">{i18n.t("PURCHASE_POINT")}</span>
                </Link> */}
              </div>
              <div className="h-full bg-white overflow-y-auto rank_scroll px-[12px] pb-[12px]">
                <div className="overflow-y-auto mt-[12px] grid grid-cols-4 gap-4">
                  {arrGift.map((x: any, y: number) => {
                    return (
                      <div
                        onClick={() => {
                          if (selectGift?.coin === x?.coin && selectGift?.image === x?.image) {
                            setSelectGift({ id: x?._id, push: false, coin: 0, image: "" });
                          } else {
                            setSelectGift({
                              id: x?._id,
                              push: false,
                              coin: x.coin,
                              image: x.image,
                            });
                          }
                        }}
                        key={y}
                        className={`${
                          selectGift?.image === x?.image && "bg-[#FEF6EE]"
                        } hover:bg-[#FEF6EE] h-[90px] cursor-pointer py-[8px] rounded-[8px] flex flex-col items-center justify-center`}
                      >
                        <img
                          src={`${URL_UPLOAD_IMAGE}/gifts/${x?.image}`}
                          className="w-[48px] h-[48px]"
                        />
                        <span className=" flex items-center">
                          {x.coin} {cts.coinIcon1}
                        </span>
                        {/* <Button
                          // onClick={() => {
                          //   setSelectGift({ ...selectGift, push: true });
                          //   setOpenBox(false);
                          // }}
                          onClick={() => {
                            if (selectGift?.coin > 0 && selectGift?.image) {
                              sendChatMessage();
                              setArrNew([...arrNew, valueChat]);
                              setValueChat({});
                            }
                          }}
                          className={`${
                            selectGift.image === x.image ? "block" : "hidden"
                          }  p-0 border-none rounded-t-none shadow-none text-[12px] w-full bg-[#EF6820] notosans_medium text-white h-[20px] flex items-center justify-center`}
                        >
                          {i18n.t("GIVE")}
                        </Button> */}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex justify-end p-[12px] bg-white ">
                <Button
                  onClick={() => {
                    if (selectGift?.coin > 0 && selectGift?.image) {
                      sendChatMessage();
                      setArrNew([...arrNew, valueChat]);
                      setValueChat({});
                    }
                  }}
                  className="bg-[#EF6820] mb-[20px] w-fit px-[20px] rounded-[8px] flex items-center justify-center h-[34px] text-white text-[14px] notosans_medium border-none hover:!text-white hover:!bg-[#EF6820]"
                >
                  {i18n.t("GIVE")}
                </Button>
              </div>
              {/* <Button className="bg-[#EF6820] w-fit px-[20px] rounded-[8px] flex items-center justify-center h-[34px] text-white text-[14px] notosans_medium border-none hover:!text-white hover:!bg-[#EF6820]">
                {i18n.t("GIVE")}
              </Button> */}
            </>
          )}

          {userInfo?._id && (
            <>
              {/* <Button
                    onClick={() => {
                      setClickHeart(true);

                      sendChatMessage();
                    }}
                    className="z-10 icon-heart w-[50px] focus:!ring-violet-300 hover:!border-[#F04438] p-0 absolute mt-[-60px] mr-[10px] btn-reaction drop-shadow-[0_2px_1.5px_#F04438] flex justify-center items-center rounded-[50%]
                     h-[50px]"
                  >
                    {cts.reactionLive}
                  </Button> */}
              {userInfo?._id && !openBox && (
                <div className="w-full flex items-start justify-end absolute mb-[90px] pr-[35px]">
                  <Button
                    onClick={() => {
                      setClickHeart(true);
                      sendChatMessage();
                    }}
                    className={`${
                      isShowChat === false && "hidden"
                    } icon-heart border-none focus:!ring-violet-300 hover:!border-none p-0 btn-reaction flex justify-center items-center`}
                  >
                    {cts.reactionLive}
                  </Button>
                </div>
              )}
              <div className={`${openBox && "hidden"} flex items-center justify-between mb-[35px]`}>
                <Input
                  value={valueChat.chat}
                  onChange={(e) =>
                    setValueChat({
                      id: Math.random(),
                      ava: ava8,
                      name: userInfo.nickName,
                      chat: e.target.value,
                    })
                  }
                  onPressEnter={() => {
                    if (Object.keys(valueChat).length !== 0 && valueChat?.chat !== "") {
                      sendChatMessage();
                      setArrNew([...arrNew, valueChat]);
                      setValueChat({});
                    }
                  }}
                  className="h-[40px] bg-[#F5F5F540] border-none inp_live_respon_cmt flex-1 mr-[10px] rounded-[20px]"
                  placeholder="メッセージを送信"
                />
                <Button
                  onClick={() => {
                    setOpenBox((prev) => !prev);
                    if (emojiPicker) {
                      setEmojiPicker(false);
                    }
                  }}
                  className="border-none mr-[10px] h-[40px] w-[40px] rounded-[50%] bg-[#1018284D] flex items-center justify-center shadow-none p-0"
                >
                  {cts.giftBox}
                </Button>
                <Button
                  onClick={() => {
                    // setSelectGift({ ...selectGift, push: true });
                    // setOpenBox(false);
                    setEmojiPicker(false);
                    if (
                      (Object.keys(valueChat).length !== 0 && valueChat?.chat !== "") ||
                      (selectGift?.coin > 0 && selectGift?.image)
                    ) {
                      sendChatMessage();
                      setArrNew([...arrNew, valueChat]);
                      setValueChat({});
                    }
                  }}
                  className="h-[40px] cursor-pointer p-0 w-[40px] flex items-center justify-center rounded-[50%] border-none text-white text-[14px] notosans_medium bg-[#EF6820]"
                >
                  {cts.sendMess}
                </Button>
              </div>
            </>
          )}
          <div
            onClick={() => {
              setIsShowInfo((prev) => !prev);
            }}
            className=""
          >
            {clickHeart && (
              <>
                <span className={`heart btn-reaction absolute`}>{cts.reactionLive}</span>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  // const arrGift = [
  //   { gift: gift, coin: 10 },
  //   { gift: gift1, coin: 10 },
  //   { gift: gift2, coin: 10 },
  //   { gift: gift3, coin: 10 },
  //   { gift: gift4, coin: 10 },
  //   { gift: gift5, coin: 10 },
  //   { gift: gift6, coin: 10 },
  //   { gift: gift7, coin: 10 },
  // ];
  setTimeout(() => {
    dispatch(getPointLiveStream({ userID: key as string }));
  }, 5000);
  useEffect(() => {
    if (joined) {
      setTimeout(() => {
        dispatch(getLiveStream({ user: key as string }));
      }, 5000);
    }
  }, [joined]);
  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }

    dispatch(fetchGiftNameList({}));
  }, []);
  useEffect(() => {
    if (key) {
      dispatch(getDetailHost(key as string));
      dispatch(getFollow({ streamer: key, type: "get_all" }));
    }
  }, [key]);

  useEffect(() => {
    setArrGift(giftsName);
  }, [giftsName]);

  useEffect(() => {
    dispatch(getUserInfo({ token: token }));
  }, []);

  useEffect(() => {
    if (key && !joined) {
      setJoined(true);
      checkLeaveRoomOld();
    }

    return () => {
      // BAD: this will remove all listeners for the 'join' event, which may
      // include the ones registered in another component
      socket.off("joinedRoom");
      socket.off("chatToClient");
      socket.off("joinRoom");
    };
  }, [checkLeaveRoomOld]);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (key) {
      dispatch(fetchChats({ receiver: key }));
    }
  }, []);

  useEffect(() => {
    // if (key) {
    if (localData.length !== 0) {
      const arr = JSON.parse(localData);
      const nickName = arr.find((x: any) => x?.id === key)?.nickNameNew;
      if (nickName) {
        setChangeName(nickName);
        setChangeNameModal(false);
      } else {
        setChangeNameModal(true);
      }
    }
  }, [localData.length]);
  return (
    <div className="flex justify-center w-full overflow-hidden">
      <div className={`${width > 800 && " pt-[12px] pb-[50px]"} size_content_hd w-full !px-[0px]`}>
        <Link
          to="/"
          className={`${
            width <= 800 && "hidden"
          } flex items-center border-none hover:!border-none shadow-none hover:!shadow-none mb-[12px]`}
        >
          <span>{cts.backHome}</span>
          <span className="text-[#475467] text-[14px] notosans_medium ml-[8px]">
            {i18n.t("HOME")}
          </span>
        </Link>
        <div className="max-800px:hidden grid grid-cols-3 gap-4 h-[85vh] max-1080px:grid-cols-2 max-1080px:justify-center max-1080px:max-w-[680px] max-1080px:items-center w-full">
          <div className="flex flex-col overflow-hidden h-[85vh] max-1080px:hidden">
            <div className="bg-white rounded-[12px] flex flex-col p-[16px]">
              <div className="flex items-start  border-b border-[#EAECF0]">
                <div
                  onClick={() => {
                    navigate(
                      {
                        pathname: "/streamer-profile",
                        search: createSearchParams({
                          key: `${detailHost?._id}`,
                        }).toString(),
                      },
                      {
                        state: {
                          prevPath: "ライブストリーム",
                        },
                      }
                    );
                  }}
                  className="relative flex items-end justify-center mr-[16px]"
                >
                  <span className="absolute bottom-[-5px] icon_live_active">{cts.liveMini}</span>
                  <div className=" rounded-[50%] h-[50px] w-[50px] cursor-pointer">
                    <img
                      className="rounded-[50%] h-[50px] w-[50px] object-cover"
                      src={
                        detailHost?.avatar
                          ? `${URL_UPLOAD_IMAGE}/avatars/${detailHost?.avatar}`
                          : noAva
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-[#101828] text-[16px] notosans_bold text_2_line">
                    {detailHost?.nickName}
                  </span>
                  <div className="flex mt-[8px] justify-between items-center pb-[16px]">
                    <div className="bg-[#EAECF0] rounded-[4px] flex items-center justify-center h-[26px] w-[76px]">
                      <span>{cts.view1Icon}</span>
                      <span className="text-[14px] notosans_normal ml-[5px]">{countView}</span>
                    </div>
                    <div className="flex items-center ">
                      <Dropdown menu={{ items }} trigger={["click"]}>
                        <Button className="border-none shadow-none">
                          <span className=" cursor-pointer">{cts.shareIcon}</span>
                        </Button>
                      </Dropdown>

                      {/* <Button className="border-none shadow-none">
                        <span className="cursor-pointer">{cts.warningIcon}</span>
                      </Button> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-[12px]">
                <span className="text-[#101828] text-[16px] notosans_bold mt-[16px]">
                  {detailHost?.nickName}
                </span>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <span>{cts.sub1Icon}</span>
                    <span className="text-[#475467] text-[14px] notosans_bold mx-[5px]">
                      {listFollow?.length || 0}
                    </span>
                    <span className="text-[#475467] text-[14px] notosans_normal">
                      {i18n.t("FOLLOWER")}
                    </span>
                  </div>
                  {__renderButtonFollow()}
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-[12px] h-[85vh] justify-center w-full">
            {key && liveStream.camera === "enable" && (
              <JWPlayer
                thumbnail={ava8}
                url={`https://live.freetemplates.xyz:1935/live/general/${key}/playlist.m3u8`}
                className="vertical-player"
              />
            )}
            {key && liveStream.camera === "disable" && (
              <img
                className="h-full w-full object-cover rounded-[12px]"
                src={
                  detailHost.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${detailHost?.avatar}` : noAva
                }
              />
            )}
          </div>

          <div className={`${width <= 1080 && "flex flex-col h-[85vh]"}`}>
            {width <= 1080 && (
              <div className="bg-white rounded-[12px] flex flex-col p-[16px]">
                <div className="flex items-start  border-b border-[#EAECF0]">
                  <div className="relative flex items-end justify-center mr-[16px]">
                    <span className="absolute bottom-[-5px] icon_live_active">{cts.liveMini}</span>
                    <div className=" rounded-[50%] h-[50px] w-[50px]">
                      <img
                        className="rounded-[50%] h-[50px] w-[50px] object-cover"
                        src={
                          detailHost.avatar
                            ? `${URL_UPLOAD_IMAGE}/avatars/${detailHost?.avatar}`
                            : noAva
                        }
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-[#101828] text-[16px] notosans_bold text_2_line">
                      {detailHost?.nickName}
                    </span>
                    <div className="flex mt-[8px] justify-between items-center pb-[16px]">
                      <div className="bg-[#EAECF0] rounded-[4px] flex items-center justify-center h-[26px] w-[76px]">
                        <span>{cts.view1Icon}</span>
                        <span className="text-[14px] notosans_normal ml-[5px]">{countView}</span>
                      </div>
                      <div className="flex items-center ">
                        <Dropdown menu={{ items }} trigger={["click"]}>
                          <Button className="border-none shadow-none">
                            <span className=" cursor-pointer">{cts.shareIcon}</span>
                          </Button>
                        </Dropdown>
                        {/* <Button className="border-none shadow-none">
                          <span className="cursor-pointer">{cts.warningIcon}</span>
                        </Button> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-[12px]">
                  <span className="text-[#101828] text-[16px] notosans_bold mt-[16px]">
                    {detailHost?.nickName}
                  </span>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <span>{cts.sub1Icon}</span>
                      <span className="text-[#475467] text-[14px] notosans_bold mx-[5px]">
                        {listFollow?.length || 0}
                      </span>
                      <span className="text-[#475467] text-[14px] notosans_normal">
                        {i18n.t("FOLLOWER")}
                      </span>
                    </div>
                    {__renderButtonFollow()}
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-col rounded-[12px] justify-between h-[85vh] max-1080px:h-full overflow-auto">
              <div>
                <div className="bg-[#FAFAFA] border-b rounded-t-[12px] border-[#D0D5DD] py-[12px] px-[16px] flex items-center justify-between">
                  <div className="flex items-center">
                    <span className="text-[#26272B] text-[16px] notosans_bold">
                      {i18n.t("CMT")}
                    </span>
                    {/* <Button
                      onClick={() => {
                        setChangeNameModal(true);
                        setEditName(true);
                      }}
                      className="flex items-center justify-center border-none hove:!border-none hover:!text-[#EF6820] shadow-none"
                    >
                      {cts.editIcon}
                      <span className="text-[#EF6820] text-[12px] notosans_normal ml-[5px]">
                        {i18n.t("EDIT_NAME")}
                      </span>
                    </Button> */}
                    {userInfo?._id && (
                      <span className="text-[14px] text-[#26272B] notosans_normal ml-[8px]">
                        (名前: {changeName ? changeName : userInfo?.nickName})
                      </span>
                    )}
                  </div>
                  {userInfo?._id && (
                    <Dropdown menu={{ items: itemsEdit }} trigger={["click"]}>
                      <Button className="border-none shadow-none p-0">
                        <span className=" cursor-pointer">{cts.iconSettings}</span>
                      </Button>
                    </Dropdown>
                  )}
                </div>
                <div
                  className={`${
                    isShowChat === false && "hidden"
                  } py-[8px] px-[8px] flex items-center border-b border-[#D0D5DD] bg-white`}
                >
                  {listRankingGift
                    ?.filter((x, y) => y === 0)
                    ?.map((it: any, idx) => {
                      return (
                        <div key={idx} className="flex flex-1 items-center pr-[20px] ">
                          <span className="icon_top1">{cts.top1}</span>
                          <img
                            className="h-[40px] w-[40px] rounded-[50%] mx-[8px] object-cover"
                            src={
                              it?.userSend[0]?.avatar
                                ? `${URL_UPLOAD_IMAGE}/avatars/${it?.userSend[0]?.avatar}`
                                : noAva
                            }
                          />
                          <div className="flex flex-col">
                            <span className="text-[#182230] text-[16px] text_1_line notosans_bold max-1590px:text-[14px]">
                              {it?.userSend[0]?.nickName}
                            </span>
                            <span className="flex items-center text-[#475467] mt-[8px] max-1590px:text-[14px] text-[16px] notosans_normal">
                              {formatMoney(Number(it?.points))} {cts.coinIcon1}
                            </span>
                          </div>
                        </div>
                      );
                    })}

                  <div
                    className={`${
                      listRankingGift?.length <= 1 && "hidden"
                    } bg-[#D0D5DD] w-px h-[18px]`}
                  ></div>
                  <div className="flex flex-1 items-center flex-col pl-[20px]">
                    {listRankingGift
                      ?.filter((x, y) => y > 0 && y <= 2)
                      ?.map((it: any, idx: any) => {
                        return (
                          <div key={idx} className="flex items-center justify-between w-full">
                            <div className="flex items-center">
                              <span>{idx === 0 ? cts.top2 : cts.top3}</span>
                              <img
                                className="h-[20px] w-[20px] rounded-[50%] mx-[8px] object-cover"
                                src={
                                  it?.userSend[0]?.avatar
                                    ? `${URL_UPLOAD_IMAGE}/avatars/${it?.userSend[0]?.avatar}`
                                    : noAva
                                }
                              />
                              <span className="text-[#182230] text_1_line text-[16px] max-1590px:text-[14px] notosans_bold">
                                {it?.userSend[0]?.nickName}
                              </span>
                            </div>
                            <span className="flex items-center text-[#475467] text-[16px] max-1590px:text-[14px] notosans_normal">
                              {formatMoney(Number(it?.points))} {cts.coinIcon1}
                            </span>
                          </div>
                        );
                      })}
                    {/* <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <span>{cts.top2}</span>
                        <img
                          className="h-[20px] w-[20px] rounded-[50%] mx-[8px] object-cover"
                          src={ava1}
                        />
                        <span className="text-[#182230] text_1_line text-[16px] max-1590px:text-[14px] notosans_bold">
                          アサヒ
                        </span>
                      </div>
                      <span className="flex items-center text-[#475467] text-[16px] max-1590px:text-[14px] notosans_normal">
                        60 {cts.coinIcon1}
                      </span>
                    </div>
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <span>{cts.top3}</span>
                        <img
                          className="h-[20px] w-[20px] rounded-[50%] mx-[8px] object-cover"
                          src={ava2}
                        />
                        <span className="text-[#182230] text_1_line text-[16px] notosans_bold max-1590px:text-[14px]">
                          櫻井絢都
                        </span>
                      </div>
                      <span className="flex items-center max-1590px:text-[14px] text-[#475467] text-[16px] notosans_normal">
                        10 {cts.coinIcon1}
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
              <div
                className={`${isShowChat === false && "hidden"}
                  !userInfo?._id && "rounded-b-[12px]"
                } flex justify-start h-full relative flex-col box_chat overflow-y-auto pt-[8px] bg-white`}
              >
                {chats
                  ?.filter((it: any) => it?.status !== "blocked")
                  ?.filter((it: any) => it?.message !== "true")
                  ?.map((x: any, y: any) => {
                    return (
                      <>
                        {x?.coins > 0 ? (
                          __renderGift(x)
                        ) : (
                          <div key={y} className="flex pb-[12px] px-[16px] items-center">
                            <img
                              className="h-[24px] w-[24px] rounded-[50%] mr-[8px] object-cover"
                              src={
                                x?.sender?.avatar
                                  ? `${URL_UPLOAD_IMAGE}/avatars/${x?.sender?.avatar}`
                                  : noAva
                              }
                            />
                            <div>
                              {x?.coin}
                              <span
                                // onClick={() => setFollow((prev) => !prev)}
                                className={`${
                                  x.coin ? "text-[#EF6820]" : "text-[#797983]"
                                } text-[13px] notosans_normal mr-[8px]`}
                              >
                                {x?.sender?.nickName}
                              </span>
                              <span className="text-[13px] notosans_normal">{x?.message}</span>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
              </div>
              <div className="w-full flex justify-end">
                {clickHeart && (
                  <>
                    <span className={`heart mt-[-70px] mr-[15px] btn-reaction absolute`}>
                      {cts.reactionLive}
                    </span>
                    {/* <span className={`heart1 mt-[-80px] mr-[15px] btn-reaction absolute`}>
                      {cts.reactionLive}
                    </span> */}
                  </>
                )}

                {userInfo?._id && !openBox && (
                  <Button
                    onClick={() => {
                      setClickHeart(true);

                      sendChatMessage();
                    }}
                    className={`${
                      isShowChat === false && "hidden"
                    } icon-heart border-none focus:!ring-violet-300 hover:!border-none p-0 absolute mt-[-60px] mr-[10px] btn-reaction flex justify-center items-center`}
                  >
                    {cts.reactionLive}
                  </Button>
                )}
              </div>
              {openBox && (
                <>
                  <div
                    style={{ boxShadow: " 0px 2px 2px rgba(0,0,0,0.1) inset" }}
                    className="flex items-center justify-between bg-white pt-[12px] px-[12px]"
                  >
                    <div className="flex items-center">
                      <span className="text-[#101828] text-[20px] notosans_bold mr-[12px]">
                        {i18n.t("SUPPORT")}
                      </span>
                      <img
                        className="h-[20px] w-[20px] rounded-[50%] mr-[8px] object-cover"
                        src={
                          userInfo?.avatar
                            ? `${URL_UPLOAD_IMAGE}/avatars/${userInfo?.avatar}`
                            : noAva
                        }
                      />
                      <span className="text-[#101828] text-[20px] notosans_bold">
                        {changeName !== "" ? changeName : userInfo?.nickName}
                      </span>
                    </div>
                    <Button
                      onClick={() => {
                        setOpenBox(false);
                        setSelectGift({ id: "", coin: 0, image: "", push: true });
                      }}
                      className="border-none shadow-none p-0"
                    >
                      {cts.close1Icon}
                    </Button>
                  </div>
                  <div className="flex items-center bg-white pb-[12px] px-[12px] justify-between">
                    <span className="text-[#101828] mr-[8px] text-[14px] notosans_normal text_1_line">
                      {i18n.t("CURRENT_POINT")}
                    </span>
                    <span className="text-[#101828] text-[16px] notosans_bold mr-[2px]">
                      {formatMoney(Number(userInfo?.points)) || 0}
                    </span>
                    <span>{cts.coinIcon1}</span>
                    <div
                      className="bg-[#EF6820] px-[16px] flex items-center text_1_line h-[32px] rounded-[8px] cursor-pointer text-white ml-[16px] notosans_medium text-[13px]"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate(
                          {
                            pathname: "/purchase-point",
                          },
                          {
                            state: {
                              prevPath: detailHost?.nickName,
                            },
                          }
                        );
                        localStorage.setItem("buyCoin", "buyCoin");
                      }}
                    >
                      <span className="text_1_line">{i18n.t("PURCHASE_POINT")}</span>
                    </div>
                    {/* <Link
                      onClick={() => localStorage.setItem("buyCoin", "buyCoin")}
                      className="bg-[#EF6820] px-[16px] flex items-center text_1_line h-[32px] rounded-[8px] cursor-pointer text-white ml-[16px] notosans_medium text-[13px]"
                      to="/purchase-point"
                    >
                      <span className="text_1_line">{i18n.t("PURCHASE_POINT")}</span>
                    </Link> */}
                  </div>
                  <div
                    style={{ boxShadow: "0px 2px rgba(0,0,0,0.1)" }}
                    className="h-full bg-white overflow-y-auto rank_scroll px-[12px] pb-[12px]"
                  >
                    <div className="overflow-y-auto mt-[12px] grid grid-cols-4 gap-4">
                      {arrGift.map((x: any, y: number) => {
                        return (
                          <div
                            onClick={() => {
                              // setSelectGift({ push: false, coin: x.coin, gift: x.gift });
                              if (selectGift?.coin === x?.coin && selectGift?.image === x?.image) {
                                setSelectGift({ id: x?._id, push: false, coin: 0, image: "" });
                              } else {
                                setSelectGift({
                                  id: x?._id,
                                  push: false,
                                  coin: x.coin,
                                  image: x.image,
                                });
                              }
                            }}
                            key={y}
                            className={`${
                              selectGift?.image === x?.image && "bg-[#FEF6EE]"
                            } hover:bg-[#FEF6EE] cursor-pointer py-[8px] rounded-[8px] flex flex-col items-center justify-center`}
                          >
                            <img
                              src={`${URL_UPLOAD_IMAGE}/gifts/${x?.image}`}
                              className="w-[48px] h-[48px]"
                            />
                            <span className=" flex items-center">
                              {x.coin} {cts.coinIcon1}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="bg-white w-full flex justify-end pr-[12px] pb-[12px] pt-[8px]">
                    <Button
                      onClick={() => {
                        if (selectGift?.coin > 0 && selectGift?.image) {
                          sendChatMessage();
                          setArrNew([...arrNew, valueChat]);
                          setValueChat({});
                        }
                      }}
                      className="bg-[#EF6820] w-fit px-[20px] rounded-[8px] flex items-center justify-center h-[34px] text-white text-[14px] notosans_medium border-none hover:!text-white hover:!bg-[#EF6820]"
                    >
                      {i18n.t("GIVE")}
                    </Button>
                  </div>
                </>
              )}
              {emojiPicker && (
                <div
                  // onBlur={() => setEmojiPicker(false)}
                  // onFocus={() => setEmojiPicker(true)}
                  className="h-[60%] emoji_picker"
                >
                  <EmojiPicker
                    // searchDisabled="true"
                    previewConfig={{ showPreview: false }}
                    // emojiStyle="google"
                    onEmojiClick={(e) =>
                      setValueChat({
                        id: Math.random(),
                        ava: ava8,
                        name: userInfo?.nickName,
                        chat: valueChat.chat ? valueChat.chat + e.emoji : e.emoji,
                      })
                    }
                    height="100%"
                    width="100%"
                  />
                </div>
              )}
              {userInfo?._id && !openBox && (
                <div
                  className={`${
                    isShowChat === false && "hidden"
                  } rounded-b-[12px] flex items-center border-[#D0D5DD] border-t`}
                >
                  <Input
                    value={valueChat.chat}
                    onChange={(e) =>
                      setValueChat({
                        id: Math.random(),
                        ava: ava8,
                        name: userInfo.nickName,
                        chat: e.target.value,
                      })
                    }
                    onPressEnter={() => {
                      if (Object.keys(valueChat).length !== 0 && valueChat?.chat !== "") {
                        sendChatMessage();
                        setArrNew([...arrNew, valueChat]);
                        setValueChat({});
                      }
                    }}
                    className="h-full border-none rounded-bl-[12px] inp_chat"
                    placeholder="メッセージを送信"
                    suffix={
                      <div className="flex items-center">
                        {!emojiPicker ? (
                          <Button
                            onClick={() => {
                              setEmojiPicker((prev) => !prev);
                              setOpenBox(false);
                            }}
                            className="border-none shadow-none h-[20px] w-[20px] p-0 mr-[15px]"
                          >
                            {cts.happyIcon}
                          </Button>
                        ) : (
                          <>
                            <Button
                              onClick={() => {
                                setEmojiPicker((prev) => !prev);
                                if (openBox) {
                                  setOpenBox(false);
                                }
                              }}
                              className="border-none shadow-none h-[20px] w-[20px] p-0 mr-[15px]"
                            >
                              {cts.happyIcon}
                            </Button>
                          </>
                        )}

                        <Button
                          onClick={() => {
                            setOpenBox((prev) => !prev);
                            if (emojiPicker) {
                              setEmojiPicker(false);
                            }
                          }}
                          className="border-none shadow-none p-0"
                        >
                          {cts.giftBox}
                        </Button>
                      </div>
                    }
                  />
                  <div
                    onClick={() => {
                      setSelectGift({ ...selectGift, push: true });
                      setOpenBox(false);
                      setEmojiPicker(false);
                      if (
                        (Object.keys(valueChat).length !== 0 && valueChat?.chat !== "") ||
                        (selectGift?.coin > 0 && selectGift?.image)
                      ) {
                        sendChatMessage();
                        setArrNew([...arrNew, valueChat]);
                        setValueChat({});
                      }
                    }}
                    className=" h-[46px] cursor-pointer w-[56px] flex items-center justify-center rounded-br-[12px] text-white text-[14px] notosans_medium bg-[#EF6820]"
                  >
                    {cts.sendMess}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {width <= 800 && (
          <div className="relative overflow-hidden h-screen w-full">
            {__renderContent()}
            <div
              className={`${
                width >= 500 && "justify-center items-center flex bg-black"
              } w-full h-screen z-100 `}
            >
              <div className={`${width >= 500 ? "!w-[400px]" : "w-full relative"} h-full `}>
                <div className={`${width <= 800 && " w-full h-full absolute z-1000"}`}></div>

                {key && liveStream.camera === "enable" && (
                  <JWPlayer
                    thumbnail={ava8}
                    url={`https://live.freetemplates.xyz:1935/live/general/${key}/playlist.m3u8`}
                    className="vertical-player"
                  />
                )}
                {key && liveStream.camera === "disable" && (
                  <img
                    className="h-full w-full object-cover"
                    src={
                      detailHost.avatar
                        ? `${URL_UPLOAD_IMAGE}/avatars/${detailHost?.avatar}`
                        : noAva
                    }
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {userInfo?._id && (
        <Modal
          centered
          className="modal_change_name"
          open={changeNameModal}
          onOk={() => setChangeNameModal(false)}
          onCancel={() => setChangeNameModal(false)}
          title={i18n.t("CHANGE_NAME")}
          footer={() => {
            return (
              <Button
                onClick={() => {
                  setChangeNameModal(false);
                  onViewLive(key, changeName);
                }}
                className="h-[40px] w-full bg-[#EF6820] text-white flex items-center justify-center hover:border-none hover:!text-white hover:bg-[#EF6820]"
              >
                {i18n.t("CONFIRM")}
              </Button>
            );
          }}
        >
          <div className="flex flex-col">
            <span className="text-[14px] text-[#1D2939] notosans_medium">
              {editName ? i18n.t("WOULD_U_LIKE_TO_CHANGE") : i18n.t("THIS_NAME_USED")}
            </span>
            <span className="mt-[20px] text-[14px] text-[#1D2939] notosans_medium">
              {i18n.t("NICK_NAME")}
            </span>
            <Input
              value={changeName}
              onChange={(e) => {
                setChangeName(e.target.value);
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default LiveStreamPage;
